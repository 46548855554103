import Vue from 'vue'
import App from './App.vue'
import Moment from "moment";
import 'moment/locale/sl';
import router from "./router/index";
import Vuelidate from "vuelidate";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { store } from './store';
import interceptorsSetup from './helpers/interceptors';
import globalFunctions from "./helpers/globals";
import * as VueGoogleMaps from 'vue2-google-maps';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueClipboard from 'vue-clipboard2';
import VueSocialSharing from 'vue-social-sharing';
import VueLazyLoad from "vue-lazyload";
import VueMobileDetection from "vue-mobile-detection";
import browserDetect from "vue-browser-detect-plugin";
import vSelect from 'vue-select';
import VueGtag from "vue-gtag";
Vue.component('v-select', vSelect);

import '@/styles/style.scss'

Vue.prototype.$axios = axios;
interceptorsSetup();

Moment.locale('SL');
Vue.prototype.moment = Moment;

Vue.prototype.$globalFunctions = globalFunctions;

// Vue.use(VueDatePicker);

Vue.config.productionTip = false;
Vue.prototype.$config = config; // eslint-disable-line

Vue.prototype.$PayPal = PayPal; // eslint-disable-line

Vue.use(Vuelidate);

Vue.use(CKEditor);

Vue.use(VueLazyLoad);

Vue.use(VueMobileDetection);

Vue.use(browserDetect);

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard);

Vue.use(VueSocialSharing);

// GoogleMaps
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAKHU6Yja1KxZKmRZQStOWSzVTq0j8heW8',
    libraries: 'places,drawing,geometry', // This is required if you use the Autocomplete plugin
    region: 'SI',
    language: 'sl',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  //// autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
});

const swalOptions = {
  confirmButtonColor: '#0687b3',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, swalOptions);

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VueGtag, {
    config: {
        id: "G-4F4C58CERW",
        params: {
            send_page_view: false
        }
    }
});

// FontAwesome
Vue.component('font-awesome-icon', FontAwesomeIcon);

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

// hide console logs on production
if(location.hostname === "pomagajmo.io") {
    console.log = function () {};
}

new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount('#app')
