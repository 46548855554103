// import {store} from '../store.js';
import _ from 'lodash';
import swal from "sweetalert2";
import Vue from "vue";


export const Toast = swal.mixin({
    toast: true,
    position: 'top-right',
    iconColor: 'white',
    customClass: {
        popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 50000,
    timerProgressBar: true,
    showCloseButton: true
});

export default {

    parseGeocompleteData(result, searchItem) {

        // console.log("result -------> ", result);
        // console.log("searchItem -------> ", searchItem);

        let geocompleteData = "";

        if (typeof (_.find(result.address_components, function (ac) {
            return ac.types[0] == searchItem
        })) != 'undefined') {
            geocompleteData = (_.find(result.address_components, function (ac) {
                return ac.types[0] == searchItem
            }).long_name);
        }
        console.log(geocompleteData);
        return geocompleteData;
    },

    getImageSrc(imageId, imageWidth) {
        return `${Vue.prototype.$config.publicUrl}media/${btoa(encodeURI('https://drive.google.com/thumbnail?authuser=0&sz=w' + (imageWidth ? imageWidth : 1000) + '&id=' + imageId))}`;
    },

    getFileSrc(fileId) {
        return `${Vue.prototype.$config.publicUrl}media/${btoa(encodeURI('https://drive.google.com/uc?id=' + fileId + '&export=download'))}`;
    },

    getDonorsFormattedWordByCount(numberOfDonors) {
        if (numberOfDonors === 1) {
            return 'donator'
        } else if (numberOfDonors === 2) {
            return 'donatorja'
        } else if (numberOfDonors === 3 || numberOfDonors === 4) {
            return 'donatorji'
        } else {
            return 'donatorjev'
        }
    }

}
