<template>
  <div>
    <router-view :key="'appNavigator_' + $route.fullPath"/>
<!--    <AccessibilityToolbar></AccessibilityToolbar>-->
  </div>
</template>

<script>
// import AccessibilityToolbar from 'vue-accessibility-toolbar'

export default {
  name: 'App',
  components: {
    // AccessibilityToolbar
  }
}
</script>

<style>

</style>
