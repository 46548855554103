import Vue from "vue";
import VueRouter from "vue-router";
// import _ from "lodash";

Vue.use(VueRouter);

const routes = [
    {
        path: '',
        component: () => import('@/layouts/PomagajmoIoPublic.vue'),
        children: [
            {
                path: "/",
                name: "Home",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Home.vue" */ "../pages/Home"
                        ),
            },
            {
                path: "/o-platformi",
                name: "AboutPlatform",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "AboutPlatform.vue" */ "../pages/AboutPlatform"
                        ),
            },
            {
                path: "/kampanja/:campaignUrlAlias",
                name: "SingleCampaign",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "SingleCampaign.vue" */ "../pages/SingleCampaign"
                        ),
            },
            {
                path: "/moje-donacije",
                name: "MyDonationsList",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_USER']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "MyDonationsList.vue" */ "../pages/donor/MyDonationsList"
                        ),
            },
            {
                path: "/izjava-o-zasebnosti",
                name: "PrivacyPolicy",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "PrivacyPolicy.vue" */ "../pages/PrivacyPolicy"
                        ),
            },
            {
                path: "/pogoji-uporabe",
                name: "TermsOfUse",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "TermsOfUse.vue" */ "../pages/TermsOfUse"
                        ),
            },
            {
                path: "/nepooblascen-dostop",
                name: "UnauthorizedAccess",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "UnauthorizedAccess.vue" */ "../pages/UnauthorizedAccess"
                        ),
            },
            {
                path: "/stran-ne-obstaja",
                name: "PageNotFound",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "PageNotFound.vue" */ "../pages/PageNotFound"
                        ),
            },
        ],
    },
    {
        path: '/deljeno',
        component: () => import('@/layouts/PomagajmoIoWidget.vue'),
        children: [
            {
                path: "/",
                name: "Widget",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Widget.vue" */ "../pages/Widget"
                        ),
            },
        ],
    },
    {
        path: '/javno',
        component: () => import('@/layouts/PomagajmoIoUnrestriced.vue'),
        children: [
            {
                path: "anketa/:surveyUuid",
                name: "UnrestrictedCampaignSurvey",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Surveys.vue" */ "../pages/ngo/campaign/SurveyUnrestricted"
                        ),
            },
        ],
    },

    {
        path: '/nvo',
        redirect: '/nvo/nadzorna-plosca',
        component: () => import('@/layouts/PomagajmoIoAdmin.vue'),
        children: [
            {
                path: "nadzorna-plosca",
                name: "NgoHome",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "NgoHome.vue" */ "../pages/ngo/NgoHome"
                        ),
            },
            {
                path: "registracija/:ngoId?",
                name: "NgoRegister",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "NgoRegister.vue" */ "../pages/ngo/NgoRegister"
                        ),
            },
            {
                path: "kampanje/:campaignStatus?",
                name: "CampaignList",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "CampaignList.vue" */ "../pages/ngo/campaign/CampaignList"
                        ),
            },
            {
                path: "kampanja/:campaignId?",
                name: "CampaignEdit",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "CampaignEdit.vue" */ "../pages/ngo/campaign/CampaignEdit"
                        ),
            },
            {
                path: "kampanja/:campaignId/objava-za-druzbene-medije",
                name: "CreateSocialMediaPost",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                  import(
                    /* webpackChunkName: "CreateSocialMediaPost.vue" */ "../pages/ngo/campaign/CreateSocialMediaPost"
                    ),
            },
            {
                path: "kampanja/:campaignId/urejanje-ankete/:surveyId?",
                name: "SurveyEdit",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "SurveyEdit.vue" */ "../pages/ngo/campaign/SurveyEdit"
                        ),
            },
            {
                path: "kampanja/:campaignId/rezultati-ankete/:surveyId?",
                name: "SurveyResults",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "SurveyResults.vue" */ "../pages/ngo/campaign/SurveyResults"
                        ),
            },
            {
                path: "kampanja/:campaignId/deli-anketo/:surveyId",
                name: "SurveyShare",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "SurveyShare.vue" */ "../pages/ngo/campaign/SurveyShare"
                        ),
            },
            {
                path: "kampanja/:campaignId/anketa/:surveyId",
                name: "CampaignSurvey",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Surveys.vue" */ "../pages/ngo/campaign/Survey"
                        ),
            },
            {
                path: "kampanja/vsebina/:campaignId",
                name: "CampaignContentEdit",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "CampaignContentEdit.vue" */ "../pages/ngo/campaign/CampaignContentEdit"
                        ),
            },
            {
                path: "kampanja/vsebina/po-zakljucku/:campaignId",
                name: "CampaignContentFinishedEdit",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "CampaignContentFinishedEdit.vue" */ "../pages/ngo/campaign/CampaignContentFinishedEdit"
                        ),
            },
            {
                path: "kampanja/donacije/:campaignId",
                name: "CampaignDonationsList",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "CampaignDonationsList.vue" */ "../pages/ngo/campaign/CampaignDonationsList"
                        ),
            },
            {
                path: "kampanja/donacije/dokazila-o-porabi/:campaignId",
                name: "CampaignContentFinishedProofs",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_NGO']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "CampaignContentFinishedProofs.vue" */ "../pages/ngo/campaign/CampaignContentFinishedProofs"
                        ),
            },
        ],
    },

    {
        path: '/podjetje',
        redirect: '/podjetje/nadzorna-plosca',
        component: () => import('@/layouts/PomagajmoIoAdmin.vue'),
        children: [
            {
                path: "nadzorna-plosca",
                name: "CompanyHome",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_COMPANY']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "AdminHome.vue" */ "../pages/company/CompanyHome"
                        ),
            },
            {
                path: "registracija/:companyId?",
                name: "CompanyRegister",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_COMPANY']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "CompanyRegister.vue" */ "../pages/company/CompanyRegister"
                        ),
            },
            {
                path: "podpora",
                name: "SupportCampaign",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_COMPANY']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "SupportCampaign.vue" */ "../pages/company/SupportCampaign"
                        ),
            },
            {
                path: "podprte-kampanje",
                name: "CompanyDonationsList",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['REGISTERED_COMPANY']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "CompanyDonationsList.vue" */ "../pages/company/CompanyDonationsList"
                        ),
            },
        ],
    },

    {
        path: '/admin',
        redirect: '/admin/nadzorna-plosca',
        component: () => import('@/layouts/PomagajmoIoAdmin.vue'),
        children: [
            {
                path: "nadzorna-plosca",
                name: "AdminHome",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['ADMIN']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "AdminHome.vue" */ "../pages/admin/AdminHome"
                        ),
            },
            {
                path: "potjevanje-kampanj",
                name: "VerifyCampaign",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['ADMIN']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "VerifyCampaign.vue" */ "../pages/admin/VerifyCampaign"
                        ),
            },
            {
                path: "potjevanje-nvo",
                name: "VerifyNgo",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['ADMIN']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "VerifyNgo.vue" */ "../pages/admin/VerifyNgo"
                        ),
            },
            {
                path: "potjevanje-podjetij",
                name: "VerifyCompany",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['ADMIN']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "VerifyCompany.vue" */ "../pages/admin/VerifyCompany"
                        ),
            },
            {
                path: "pregled-podjetja/:companyId",
                name: "OverviewCompany",
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresPermission: ['ADMIN']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "OverviewCompany.vue" */ "../pages/admin/OverviewCompany"
                        ),
            },
        ],
    },

    {
        path: '/auth',
        component: () => import('@/layouts/PomagajmoIoAuth.vue'),
        children: [
            {
                name: "VerifyRegistration",
                path: "verify",
                component: () => import("@/pages/auth/verify-registration")
            },
            {
                name: "SetPassword",
                path: "set-password",
                component: () => import("@/pages/auth/set-password")
            },
            {
                name: "ForgottenPassword",
                path: "forgotten-password",
                component: () => import("@/pages/auth/forgotten-password")
            },
            {
                name: "VerifyVote",
                path: "verify-vote",
                component: () => import("@/pages/auth/verify-vote")
            },
        ],
    },

    {
        path: '/pdf',
        component: () => import('@/layouts/PomagajmoIoPdf.vue'),
        children: [
            {
                path: "potrdilo-o-donaciji/:donationId",
                name: "DonationCertificate",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "DonationCertificate.vue" */ "../pages/pdf/DonationCertificate"
                        ),
            },
            {
                path: "pogodba-nvo/:ngoId",
                name: "ContractNgo",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "ContactNgo.vue" */ "../pages/pdf/ContractNgo"
                        ),
            },
            {
                path: "pogodba-podjetje/:companyId",
                name: "ContractCompany",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "ContractCompany.vue" */ "../pages/pdf/ContractCompany"
                        ),
            },
            {
                path: "aneks-a/:ngoId/:companyId/:campaignId",
                name: "AnnexA",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "AnnexA.vue" */ "../pages/pdf/AnnexA"
                        ),
            },
            {
                path: "aneks-b/:ngoId/:companyId/:campaignId",
                name: "AnnexB",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "AnnexB.vue" */ "../pages/pdf/AnnexB"
                        ),
            },
            {
                path: "aneks-c/:ngoId/:companyId/:campaignId",
                name: "AnnexC",
                props: true,
                meta: {
                    requiresAuth: false,
                    requiresPermission: []
                },
                component: () =>
                    import(
                        /* webpackChunkName: "AnnexC.vue" */ "../pages/pdf/AnnexC"
                        ),
            }

        ]
    },

    {path: '*', redirect: '/stran-ne-obstaja'}
];

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "open",
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return window.scrollTo({
                top: document.querySelector(to.hash).offsetTop,
                behavior: "smooth"
            });
        } else {
            return {x: 0, y: 0};
        }
    }
});

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth) {
        let userAuthenticated = localStorage.getItem('user') && localStorage.getItem('jwt');
        if (userAuthenticated) {
            next();
        } else {
            router.push({name: 'UnauthorizedAccess'}).catch(() => {
            });
        }
    } else {
        next();
    }
});

router.afterEach(() => {
    const preLoading = document.getElementById("loading_wrap");
    if (preLoading) {
        preLoading.style.display = "none";
    }
});
export default router;
