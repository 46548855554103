// import Vue from 'vue';
import axios from 'axios';

export default function setup() {
  axios.interceptors.request.use(_config => {
    const token = localStorage.getItem("jwt") ? localStorage.getItem("jwt") : null;
    console.log("_config", _config);
    if(token && (!_config.url.includes('api/auth/login') && !_config.url.includes('api/auth/register') && !_config.url.includes('api/auth/verify') && !_config.url.includes('api/auth/forgotten-password'))) {
        _config.headers.common["Authorization"] = "Bearer " + token;
    }
    return _config
  }, error => {
    return Promise.reject(error)
  })
}
