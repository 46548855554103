import Vue from "vue";
import Vuex from "vuex";
// import globalFunctions from '../helpers/globalFunctions';

Vue.use(Vuex);

export const store = new Vuex.Store({
	state: {
		tokenData: null,
		userData: null,
		ngoData: null,
		companyData: null,
	},
	mutations: {
		SET_TOKEN_DATA(state, payload) {
			console.log("SET_TOKEN_DATA payload", payload);
			Vue.set(state, "tokenData", payload);
		},

		SET_USER_DATA(state, payload) {
			console.log("SET_USER_DATA payload", payload);
			Vue.set(state, "userData", payload);
		},

		SET_NGO_DATA(state, payload) {
			console.log("SET_NGO_DATA payload", payload);
			Vue.set(state, "ngoData", payload);
		},

		SET_COMPANY_DATA(state, payload) {
			console.log("SET_COMPANY_DATA payload", payload);
			Vue.set(state, "companyData", payload);
		},

		RESET_STORE(state) {
			Vue.set(state, "tokenData", null);
			Vue.set(state, "userData", null);
			Vue.set(state, "ngoData", null);
			Vue.set(state, "companyData", null);
		}
	},
	actions: {
		setTokenData(context, data) {
			context.commit("SET_TOKEN_DATA", data);
		},
		setUserData(context, data) {
			context.commit("SET_USER_DATA", data);
		},
		async retrieveNgoData(context, force) {
			if(!force && context.state.ngoData) {
				return
			}

			await Vue.prototype.$axios.get(`${Vue.prototype.$config.rootUrl}ngos/${this.getters.getUserData.ngos[0].id}`)
				.then(resp => {
					console.log("resp", resp.data);
					context.commit("SET_NGO_DATA", resp.data);
				})
				.catch(err => {
					console.error(err)
				})
				.finally(() => {

				})
		},
		async retrieveCompanyData(context, force) {
			if(!force && context.state.companyData) {
				return
			}

			console.log("company ... user:", this.getters.getUserData);

			await Vue.prototype.$axios.get(`${Vue.prototype.$config.rootUrl}companies/${this.getters.getUserData.companies[0].id}`)
				.then(resp => {
					console.log("resp", resp.data);
					context.commit("SET_COMPANY_DATA", resp.data);
				})
				.catch(err => {
					console.error(err)
				})
				.finally(() => {

				})
		},

		resetStore(context) {
			context.commit("RESET_STORE");
		},
	},
	getters: {
		getTokenData: state => state.tokenData,
		getUserData: state => state.userData ? state.userData : JSON.parse(localStorage.getItem("user")),
		getNgoData: state => state.ngoData,
		getCompanyData: state => state.companyData
	}
});
